<template>
  <div class="SingleItemH5">
    <Nav :title="SingleitemData.challenge_title"></Nav>
    <div class="Body">
      <div class="header">
        <div class="header-left">
          <img :src="SingleitemData.challenge_image_path" alt="" />
        </div>
        <div class="header-right">
          <h1>{{ SingleitemData.challenge_title }}</h1>
          <div class="date">
            <!-- <i class="el-icon-timer"></i> -->
            开始时间：{{ SingleitemData.start_time }}<br />
            结束时间：{{ SingleitemData.en_time }}
          </div>
          <div class="attr">
            {{
                    SingleitemData.challenge_status == 0
                      ? "进行中"
                      : SingleitemData.challenge_status == 1
                      ? "已结束"
                      : "已暂停"
                  }}
          </div>
        </div>
      </div>
      <div class="competition-page-content">
        <div class="cmpt-tabs">
          <div
            @click="OclickEntrance(0)"
            :class="EntranceChangeState == 0 ? 'active' : ''"
            class="cmpt-tab"
          >
            比赛详情
          </div>
          <div
            @click="OclickEntrance(1)"
            :class="EntranceChangeState == 1 ? 'active' : ''"
            class="cmpt-tab"
          >
            排名榜
          </div>
          <div
            v-if="IsLogin"
            @click="OclickEntrance(2)"
            :class="EntranceChangeState == 2 ? 'active' : ''"
            class="cmpt-tab"
          >
            分数看板
          </div>
        </div>
        <div class="cmpt-content">
          <div class="markdown-container" v-if="EntranceChangeState == 0">
            <p id="imgs" v-html="SingleitemData.challenge_detail"></p>
            <div style="height: 100px"></div>
          </div>
          <div class="markdown-container" v-if="EntranceChangeState == 2">
            <div class="LineChar" id="sLineChars"></div>
            <div style="height: 100px"></div>
          </div>
          <div class="markdown-container" v-if="EntranceChangeState == 1">
            <h3>已提交结果（按得分排名，每人只排最高分）</h3>
            <el-row>
              <el-col :span="10">
                <el-input
                  size="mini"
                  v-model="rankingeParams.keywords"
                  @keyup.enter.native="getdataranking($route.query.id)"
                  clearable
                  placeholder="请输入用户关键字"
                ></el-input>
              </el-col>
              <el-col :span="8" style="padding-left: 10px">
                <el-select size="mini" v-model="rankingeParams.pageSize" clearable placeholder="选择条/页">
                  <el-option
                    v-for="item in rankinglist"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="2" style="padding-left: 10px">
                <el-button
                  size="mini"
                  type="primary"
                  @click="getdatarankings($route.query.id)"
                  >搜 索</el-button
                >
              </el-col>
            </el-row>
            <table>
              <thead>
                <tr>
                  <th style="width: 70px">实时排名</th>
                  <th>昵称</th>
                  <!-- <th>学号</th> -->
                  <!-- <th>学校</th> -->
                  <!-- <th>提交时间</th> -->
                  <th>模型分数</th>
                  <th
                    style="width: 80px"
                    @click="ChangeRank($route.query.id, 'score')"
                    :style="
                      rankingeParams.filtrate == 'score'
                        ? 'color:rgb(24,144,255);cursor: pointer;'
                        : 'cursor: pointer;'
                    "
                  >
                    排位分
                    <i
                      :style="
                        rankingeParams.filtrate == 'score'
                          ? ''
                          : 'transform: rotate(-180deg)'
                      "
                      class="el-icon-caret-top"
                    ></i>
                  </th>
                  <th
                    style="width: 60px"
                    @click="ChangeRank($route.query.id, 'integral')"
                    :style="
                      rankingeParams.filtrate == 'integral'
                        ? 'color:rgb(24,144,255);cursor: pointer;'
                        : 'cursor: pointer;'
                    "
                  >
                    积分
                    <i
                      :style="
                        rankingeParams.filtrate == 'integral'
                          ? ''
                          : 'transform: rotate(-180deg)'
                      "
                      class="el-icon-caret-top"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in rankingListAll" :key="item.rank" :style="RankingStyle(item.rank)">
                  <td>
                    <span
                      :style="
                        item.rank == 1 || 2 || 3
                          ? 'font-weight: bold;padding-left:4px'
                          : ''
                      "
                      >{{ item.rank }}</span
                    >
                    <img
                      v-if="item.rank == 1"
                      style="width: 20px; height: 20px; vertical-align: middle"
                      src="../../assets/imgs/trophy1.png"
                      alt=""
                    />
                    <img
                      v-if="item.rank == 2"
                      style="width: 20px; height: 20px; vertical-align: middle"
                      src="../../assets/imgs/trophy2.png"
                      alt=""
                    />
                    <img
                      v-if="item.rank == 3"
                      style="width: 20px; height: 20px; vertical-align: middle"
                      src="../../assets/imgs/trophy3.png"
                      alt=""
                    />
                  </td>
                  <td>{{ item.nickname }}</td>
                  <!-- <td>{{ item.student_num }}</td> -->
                  <!-- <td>{{ item.school_name }}</td> -->
                  <!-- <td>{{ item.update_time }}</td> -->
                  <td>{{ item.max_score }}</td>
                  <td>{{ Number(item.rank_score).toFixed(2) }}</td>
                  <td>{{ item.liveness }}</td>
                </tr>
              </tbody>
            </table>
            <!-- <pagination
              style="zoom: 0.49"
              :total="rankingTotal"
              :page.sync="rankingeParams.pageNum"
              :limit.sync="rankingeParams.pageSize"
              @pagination="getdataranking(null)"
            /> -->
            <!-- <van-pagination v-model="rankingeParams.pageSize" :total-items="rankingTotal" :items-per-page="5" /> -->
            <van-pagination
              v-if="rankingListAll.length"
              @change="getdataranking(null)"
              v-model="rankingeParams.pageNum"
              :items-per-page="rankingeParams.pageSize"
              :page-count="Math.ceil(rankingTotal/(rankingeParams.pageSize))"
              :total-items="rankingTotal"
              
            />
            <!-- :show-page-size="5" -->
            <div style="height: 100px"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="signup-wrapper" v-if="SingleitemData.challenge_status == 0">
      <span @click="RegistrationParameters" v-if="EnrollState == -1"
        >立即报名</span
      >
      <span v-if="EnrollState == 1">已报名</span>
      <span v-if="EnrollState == 0">审核中</span>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/H5/navigation.vue";
import {
  match_search,
  searchCompetitionDocuments,
  search_score,
  user_score_rank,
  competition_entry,
  upload_num,
  is_enter_match,
  search_score_everyday,
  integral_rank,
} from "../../api/SingleItem.js";
import { Notify } from "vant";
import { getToken } from "@/utils/auth";
import * as echarts from "echarts";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      title: "",
      Height: document.documentElement.clientHeight,
      SingleitemData: {},
      EntranceChangeState: 0,
      rankingeParams: {
        //排名分页字段
        keywords: "",
        pageNum: 1,
        pageSize: 50,
        challenge_id: null,
        filtrate: "score",
      },
      rankinglist:[
        {
          label:'10条/页',
          value:10,
        },
        {
          label:'20条/页',
          value:20,
        },
        {
          label:'50条/页',
          value:50,
        },
        {
          label:'100条/页',
          value:100,
        }
      ],
      rankingListAll: [],
      rankingTotal: 0,
      EnrollState: -1, //报名入口按钮
      IsLogin: getToken(),
    };
  },
  created() {
  },
  mounted(){
    // 默认展示比赛简介
    this.OclickEntrance(0);
    // 判断是否为个人信息页面跳转传参
    if (this.$route.query.state) {
      this.OclickEntrance(this.$route.query.state);
    }
    // 判断报名入口显示状态
    if (getToken()) {
      this.GetEnrollState();
    } else {
      this.EnrollState = -1;
    }
  },
  methods: {
    RankingStyle(rank){
            if (rank == 1 || rank == 2 || rank == 3) {
                return {
                    backgroundColor: "rgba(245, 100, 80, 0.3)",
                    fontWeight: 600,
                };
            }else if (rank == 4 || rank == 5 || rank == 6) {
                return {
                    backgroundColor: "rgba(236,167,73, 0.3)",
                    fontWeight: 600,
                };
            }else if (rank == 7 || rank == 8 || rank == 9 || rank == 10) {
                return {
                    backgroundColor: "rgba(73,205,133, 0.3)",
                    fontWeight: 600,
                };
            }else{
                return ''
            }
        },
    OclickEntrance(item) {
      if (item == 0) {
        this.getdata(this.$route.query.id);
        this.EntranceChangeState = item;
      } else if (item == 1) {
        this.getdataranking(this.$route.query.id);
        this.EntranceChangeState = item;
      } else if (item == 2) {
        this.EntranceChangeState = item;
        this.$nextTick(() => {
          search_score_everyday({
            challenge_id: this.$route.query.id,
          }).then((res) => {
            this.getdatascoreLineChar(res.date_list, res.socre_list);
          });
        });
      } else {
      }
    },
    // 报名比赛
    RegistrationParameters() {
      if (getToken()) {
        competition_entry({
          challenge_id: this.SingleitemData.challenge_id,
        })
          .then((res) => {
            if (res.code == 200) {
              Notify({ type: "success", message: "报名成功" });
              // this.EnrollState = 1
              this.GetEnrollState();
            }
          })
          .catch(() => {
            // this.$message({
            //   type: "error",
            //   message: "报名失败",
            // });
            Notify({ type: "danger", message: res.msg });
          });
      } else {
        Notify({ type: "danger", message: "请先登录用户" });
        this.$router.push("/H5login");
      }
    },
    // 是否报名
    GetEnrollState() {
      is_enter_match({ challenge_id: this.$route.query.id })
        .then((res) => {
          if (res.code == 200) {
            this.EnrollState = res.registration_status;
          }
        })
        .catch((err) => {
          this.EnrollState = err.registration_status;
        });
    },
    // 获取比赛简介
    getdata(id) {
      match_search({
        challenge_id: id,
      }).then((res) => {
        this.SingleitemData = res;
        this.SingleitemData.challenge_detail =
          this.SingleitemData.challenge_detail.replace(
            /<img/g,
            "<img style='width:100%;height:auto;'"
          );
      });
    },
    // 获取个人排名
    getdataranking(id) {
      var ID = id || this.$route.query.id;
      this.rankingeParams.challenge_id = ID;
      integral_rank(this.rankingeParams).then((res) => {
        if (res.code == 200) {
          this.rankingListAll = res.data.list;
          this.rankingTotal = res.data.total || 0;
        }
      });
    },
    ChangeRank(id, type) {
      var ID = id || this.$route.query.id;
      this.rankingeParams.challenge_id = ID;
      this.rankingeParams.filtrate = type;
      integral_rank(this.rankingeParams).then((res) => {
        if (res.code == 200) {
          this.rankingListAll = res.data.list;
          this.rankingTotal = res.data.total || 0;
        }
      });
    },
    // 搜索获取个人排名
    getdatarankings(id) {
      var ID = id || this.$route.query.id;
      this.rankingeParams.challenge_id = ID;
      this.rankingeParams.pageNum = 1;
      // this.rankingeParams.pageSize = 10;
      integral_rank(this.rankingeParams).then((res) => {
        if (res.code == 200) {
          this.rankingListAll = res.data.list;
          this.rankingTotal = res.data.total || 0;
        }
      });
    },
    getdatascoreLineChar(date_list, socre_list) {
      var myChart = echarts.init(document.getElementById("sLineChars"));
      var fontColor = "#30eee9";
      var option = {
        backgroundColor: "#11183c",
        grid: {
          left: "5%",
          right: "5%",
          top: "5%",
          bottom: "12%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#fff",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(126,199,255,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(126,199,255,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(126,199,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              color: fontColor,
              interval: 0,
              rotate: 30,
              textStyle: {
                color: "#c3dbff", //更改坐标轴文字颜色
                fontSize: 8, //更改坐标轴文字大小
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#397cbc",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#195384",
              },
            },
            data: date_list,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            min: 0,
            max: 1,
            axisLabel: {
              formatter: "{value}",
              textStyle: {
                color: "#2ad1d2",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#27b4c2",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#11366e",
              },
            },
          },
        ],
        series: [
          {
            name: "每日分数",
            type: "line",
            stack: "AUC",
            symbol: "circle",
            symbolSize: 2,
            itemStyle: {
              normal: {
                color: "#0092f6",
                lineStyle: {
                  color: "#0092f6",
                  width: 1,
                },
                label: {
                  show: true,
                  position: "top",
                  formatter: function (p) {
                    return p.value > 0 ? p.value : "";
                  },
                },
              },
            },
            markPoint: {
              itemStyle: {
                normal: {
                  color: "red",
                },
              },
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(10,219,250,.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(10,219,250, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(10,219,250, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: socre_list,
          },
        ],
        dataZoom: [
          {
            show: true,
            height: 10,
            xAxisIndex: [0, 20],
            bottom: 20,
            start: 0,
            end: Number((socre_list.length / 2) * 10),
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "100%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 10,
            start: 1,
            end: 0,
          },
        ],
        // dataZoom: [
        //   {
        //     show: true,
        //     height: 12,
        //     xAxisIndex: [0],
        //     bottom: 20,
        //     start: 0,
        //     end: 70,
        //     handleIcon:
        //       "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
        //     handleSize: "100%",
        //     handleStyle: {
        //       color: "#d3dee5",
        //     },
        //     textStyle: {
        //       color: "#fff",
        //     },
        //     borderColor: "#90979c",
        //   },
        //   // {
        //   //     type: "inside",
        //   //     show: true,
        //   //     height: 10,
        //   //     start: 1,
        //   //     end: 35,
        //   // },
        // ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped type="text/css">
#imgs img {
  width: 100% !important;
}
.SingleItemH5 {
  width: 100%;
  height: 100vh;
  // overflow-y: auto;
  .signup-wrapper {
    width: 100%;
    position: fixed;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2000;
    span {
      width: 70%;
      height: 46px;
      background: linear-gradient(270deg, #3a6de6, #5484f7);
      box-shadow: 0 0.10667rem 0.16rem 0 rgb(58 109 230 / 25%),
        0 0 0 0 hsl(0deg 0% 100% / 50%);
      border-radius: 30px;
      display: inline-block;
      margin: 0 auto;
      font-size: 1rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fefffe;
      line-height: 46px;
      text-align: center;
      margin-left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .Body {
    box-sizing: border-box;
    // width: calc(100% - 20px);
    width: 100%;
    // height: 100%;
    height: calc(100% - 44px);
    margin: 0 auto;
    margin-top: 44px;
    .header {
      display: flex;
      width: 100%;
      //   height: 126px;
      //   height: 300px;
      margin: 10px auto;
      background: #fff;
      border-radius: 0.37333rem;
      //   padding: 18px 7px;
      flex-wrap: wrap;
      .header-left {
        width: 100%;
        // height: 200px;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .header-right {
        margin-left: 10px;
        position: relative;
        // width: calc(60% - 10px);
        width: 100%;

        h1 {
          margin: 0;
          width: 100%;
          height: 36px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #232526;
          //   line-height: 18px;
          font-weight: 600;
          -webkit-transform: translateY(-0.05333rem);
          transform: translateY(-0.05333rem);
          line-height: 36px;
        }
        .date {
          width: 70%;
          height: 24px;
          height: 34px;
          font-size: 12px;
          line-height: 16px;
          display: inline-block;
          //   color: red;
        }
        .attr {
          width: 30%;
          line-height: 26px;
          line-height: 2rem;
          text-align: center;
          //   margin: 0 auto;
          margin-top: -2px;
          position: absolute;
          right: 12px;
          bottom: 4px;
          display: inline-block;
          background: linear-gradient(90deg, #5383f6, #3b6ee7);
          box-shadow: 0 0.10667rem 0.16rem 0 rgb(58 109 230 / 25%),
            0 0 0.02667rem 0 hsl(0deg 0% 100% / 50%);
          border-radius: 0.50667rem;
          font-size: 1rem;
          color: #fff;
          text-align: center;
        }
      }
    }
    .competition-page-content {
      width: 100%;
      width: calc(100% - 20px);
      margin: 0 auto;
      border-radius: 10px;
      .cmpt-tabs {
        display: flex;
        justify-content: space-between;
        .cmpt-tab {
          // width: 50%;
          flex: 1;
          height: 50px;
          position: relative;
          border: 1px solid #d8e3ff;
          background: linear-gradient(180deg, #f6f9ff, #eaf0fe);
          -webkit-transform: translateY(0.02667rem);
          transform: translateY(0.02667rem);

          border-radius: 10px 10px 0 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          text-align: center;
          line-height: 50px;
          font-size: 14px;
        }
        .active {
          border-bottom: none;
          background: linear-gradient(1turn, #fff, #eaf0fe);
          font-weight: 600;
          color: #3a6de6;
        }
      }
      .cmpt-content {
        padding-bottom: 44px;
        box-sizing: border-box;
        .markdown-container {
          width: 100%;
          height: 200px;
          p img {
            width: 100% !important;
          }
          p > img {
            width: 100% !important;
          }
          //   padding: 16px;
          h3 {
            color: #2b67ee;
            font-size: 14px;
            span {
              color: red;
              font-size: 12px;
            }
          }
          .LineChar {
            width: 100%;
            min-height: 300px;
            margin: 0;
            padding: 0;
          }
          table {
            font-size: 1em;
            width: 100%;
            background: #fff;
            margin: 1em 0;
            border: 1px solid rgba(34, 36, 38, 0.15);
            -webkit-box-shadow: none;
            box-shadow: none;
            border-radius: 0.28571429rem;
            text-align: left;
            color: rgba(0, 0, 0, 0.87);
            border-collapse: separate;
            border-spacing: 0;

            thead {
              box-shadow: none;

              tr {
                th {
                  cursor: auto;
                  background: #f9fafb;
                  text-align: inherit;
                  color: rgba(0, 0, 0, 0.87);
                  padding: 0.92857143em 0.78571429em;
                  vertical-align: inherit;
                  font-style: none;
                  font-weight: 700;
                  text-transform: none;
                  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
                  border-left: 1px solid rgba(34, 36, 38, 0.1);
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 0.78571429em 0.78571429em;
                  text-align: inherit;
                  border-left: 1px solid rgba(34, 36, 38, 0.1);
                  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>